<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="mt-2">
            Servicios por defecto
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reload++" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :key="reload">
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary">
                    <v-tab href="#tab-1">
                        Servicios por defecto
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <block v-if="loading"></block>
                        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert"></alerts>
                        <v-card flat>
                            <v-card-text class="p-0">
                                <v-row>
                                    <v-col class="tabsNh" cols="12">
                                        <v-col cols="12" style="display:flex; justify-content: flex-end;">
                                            <v-btn small class="btnsave mr-1" @click.prevent.stop="saveDefaultServices">
                                                <v-icon>mdi-content-save</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-col>

                                    
                                </v-row>

                                <v-row class="background border-box mt-6 pa-4">
                                    <v-col cols="12" >
                                        <v-btn class="btn-add mb-2" @click.prevent.stop="showServices = true">
                                            Agregar servicio
                                        </v-btn>

                                        <v-dialog v-model="showServices" width="60%" scrollable>
                                            <v-card>
                                                <v-card-title>
                                                    Lista de servicios disponibles
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-row>
                                                        <GeneralFilter :filterEndpoint="$API.services.newGetAllServices" :search="{}" @emptyFilter="getAllServices" @filtered="filterHandler">
                                                            <DxDataGrid :scrolling="{useNative: true}" :data-source="services">
                                                                <DxColumn caption="" cell-template="add-service" />
                                                                <template #add-service="{ data }">
                                                                    <a @click="addServiceHasDefault(data.data)">
                                                                        <v-icon>mdi-plus</v-icon>
                                                                    </a>
                                                                </template>
                                                                <DxColumn caption="Código de servicio" data-field="CodeService" />
                                                                <DxColumn caption="Nombre" data-field="NameService" />
                                                                <DxColumn caption="Nombre a facturar" data-field="Name" />
                                                                <DxColumn caption="Tipo de servicio" data-field="ServiceType" />
                                                                <DxColumn caption="Precio" data-field="Price" />
                                                            </DxDataGrid>
                                                        </GeneralFilter>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>

                                    <v-col cols="12">
                                        <DxDataGrid :scrolling="{useNative: true}" id="gridContainer" ref="gridContainer"
                                            :data-source="servicesDefault" :allow-column-reordering="true" :show-borders="true" :column-auto-width="true" @row-updated="UpdateRow">
                                            <DxPaging :enabled="false" />
                                            <DxEditing :allow-updating="true" :allow-deleting="true" :confirmDelete="false" :select-text-on-edit-start="true" start-edit-action="click" 
                                                mode="cell" :use-icons="true" />
                                            <DxKeyboardNavigation :edit-on-key-press="true" enter-key-action="moveFocus" enter-key-direction="column" />

                                            <DxColumn :allow-editing="true" caption="Orden" data-field="OrderDefault" sort-order="asc" />
                                            <DxColumn :allow-editing="false" caption="Código de servicio" data-field="CodeService"/>
                                            <DxColumn :allow-editing="false" caption="Nombre" data-field="NameService"/>
                                            <DxColumn :allow-editing="false" caption="Nombre a facturar" data-field="Name"/>
                                            <DxColumn :allow-editing="false" caption="Precio" data-field="Price"/>
                                        </DxDataGrid>
                                    </v-col>
                                </v-row>

                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import { DxDataGrid, DxColumn, DxEditing, DxPaging, DxKeyboardNavigation } from "devextreme-vue/data-grid";
    import Block from '@/components/Block.vue';
    import Alerts from '@/components/Alerts.vue';
import GeneralFilter from './../../../components/GeneralFilter.vue'
    export default {
        name: "TemplateServices",
        components: { Alerts, Block, DxDataGrid, DxColumn, DxPaging, DxKeyboardNavigation, DxEditing, GeneralFilter },
        props: ["win"],
        data() {
            return {
                reload: 0,
                tab: null,
                loading: false,
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                servicesDefault: [],
                services: [],
                showServices: false,
            }
        },
        mounted() {
            this.getDefaultServices()
            this.getAllServices()
        },
        computed: {

        },
        methods: {
            async getDefaultServices() {
                this.$API.services.getDefaultServices()
                    .then(response => {
                        this.servicesDefault = response
                    })
            },
            async getAllServices() {
                this.$API.services.newGetAllServices()
                    .then(response => {
                        this.services = response
                    })
            },
            async addServiceHasDefault(data) {
                if (!this.servicesDefault.find(item => item.CodeService == data.CodeService)) {
                    this.servicesDefault.push({ ...data, OrderDefault: 0 })
                }
            },
            async filterHandler(response) {
                this.services = response
            },
            async saveDefaultServices() {
                this.loading = true
                this.$API.services.saveServicesDefault({ data: this.servicesDefault })
                    .then(response => {
                        this.loading = false
                        if(response?.status == "OK") {
                            this.showAlert("success", "Exito", "La plantilla de servicios por defecto ha sido guardado.")
                        }
                        else {
                            throw new Error('Error')
                        }
                    })
                    .catch(() => {
                        this.loading = false
                        this.showAlert("danger", "Error", "Ha ocurrido un problema, por favor intente nuevamente.")
                    })
            },
            showAlert(type, header, body, options = null){
                type = (type == null) ? "danger" : type
                this.alert.type = type
                this.alert.header = header
                this.alert.body = body
                this.alert.show = true
                this.alert.options = (options != null) ? options : null
            },
            closeAlert() {
                this.alert.show = false
            },
        }
    }
</script>
<style scoped>
.btndelete {
    background-color: #E3191E !important;
    color: white;
}

.btn-add {
    background-color: #3498DB !important;
    color: white;
}

.btnclean {
    background-color: #F29D35 !important;
    color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.border-box {
    border-radius: 15px !important;
}

.background {
    background: white !important;
    color: white;
}

.tabsNh{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
}
</style>